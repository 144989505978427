<template>
  <div class="scratcher">
    <div class="scratcher_header">
      <div class="scratcher_header-box">
        <div class="scratcher_header-box-left">
          <div class="scratcher_header-box-logo">
            <img src="../../../../static/common/wap/hongshi.jpg" alt="">
          </div>
          <div class="scratcher_header-box-edition">
            <h4>红狮</h4>
            <p>应用版本：5.0.0</p>
            <p>更新时间：2024 年 09 月 02 日</p>
          </div>
        </div>
        <div class="scratcher_header-box-btn" @click="handleDownload">
          下载APP
        </div>
      </div>
    </div>
    <div class="scratcher_introduce">
      <p>
        应用权限：<span class="hover" @click="checkPower(1)">查看权限</span>
        隐私权限：<span class="hover" @click="checkPower(2)">查看权限</span>
      </p>
      <p>
        APP开发者：香港红狮集团有限公司
        应用名称：红狮
      </p>
    </div>
    <div class="scratcher_step-one">
      <img class="scratcher_step-one-title" src="/subject/scratcher/title.png" alt="">
      <img class="scratcher_step-one-prize" src="/subject/scratcher/prize.png" alt="">
      <img class="scratcher_step-one-rules" src="/subject/scratcher/rules.png" alt="">
      <div class="scratcher-btns">
        <img class="hover" src="/subject/scratcher/download.png" alt="" @click="handleDownload">
        <img class="hover" src="/subject/scratcher/register.png" alt="" @click="handleRegister">
      </div>
    </div>
    <div class="scratcher_step-two">
      <div class="scratcher_step-two-leftBtn" @click="handleRegister"></div>
      <div class="scratcher_step-two-rightBtn" @click="handleRegister"></div>
      <img class="w-full" src="/subject/scratcher/step2.png" alt="">
    </div>
    <div class="scratcher-btns">
      <img class="hover" src="/subject/scratcher/download.png" alt="" @click="handleDownload">
      <img class="hover" src="/subject/scratcher/register.png" alt="" @click="handleRegister">
    </div>
    <img class="w-full" src="/subject/scratcher/step3.png" alt="">
    <div class="scratcher_tips">温馨提示：市场有风险，投资需谨慎</div>

    <!-- 注册弹窗 -->
    <el-dialog
      class="register-dialog"
      :visible.sync="dialogVisible"
      width="95%"
      :show-close="false"
    >
      <img class="hover register-close" src="/subject/scratcher/close.png" alt="" @click="dialogVisible = false">
      <div class="register-title">
        注册开始挑战<br/>
        单次最高奖金<span>160元</span>
      </div>
      <el-form class="register-form" :model="form" ref="formRef">
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" placeholder="验证码"></el-input>
          <span class="register-form-code" v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
          <span class="register-form-code register-form-code-unfocus" v-else>重新获取({{ verSecond }})</span>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="pwd-input" v-model="form.password" placeholder="8-16位,包含数字、大小写字母及英文符号" show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="register-tips">点击开立账户，即代表已阅读并同意<span>《用户协议》</span></div>
      <img class="hover" src="/subject/scratcher/registerBtn.png" width="100%" alt="" @click="handleSubmit">
    </el-dialog>
    <div v-if="controlImgCodeDialog" class="codeDialog">
      <img :src="imgcodelocal" alt="">
      <p>
        <span>图形验证码:</span>
        <input type="text" v-model="form.codeNumber">
      </p>
      <div>
        <button @click="() => { this.controlImgCodeDialog = false }">取消</button>
        <button @click="confirm(form.codeNumber)">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getGuid } from '../../../../utils/guid';
import { verifyImgCode, quickRegister, originApi } from '../../../../api/info';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        phone: '',
        code: '',
        password: '',
      },
      timer: null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog: false,
      // 图片验证码地址
      imgcodelocal: '',
      // 控制显示验证码或读秒
      verControl: true,
      guid: '',
    }
  },
  created() {
    this.$emit('controlShow', true);
  },
  methods: {
    ...mapActions('common', {
      handleDownload: 'handleDownload',
    }),
    checkPower(attr) {
      if (attr == 1) {
        this.$router.push({
          name: "Application",
        });
      } else {
        this.$router.push({
          name: "Privacy",
        });
      }
    },
    handleRegister() {
      this.dialogVisible = true;
    },
    // 点击获取验证码
    verCode(attr) {
      if (this.isPhone(attr)) {
        this.controlImgCodeDialog = true;
        this.guid = getGuid();
        this.imgcodelocal = originApi + '/v4/U104?key=' + this.guid;
        // this.imgcodelocal = 'https://testwww.rlc525.com' + '/api/proxy/v4/U104?key=' + this.guid;
      } else {
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 提交验证码
    confirm(attr) {
      if (attr) {
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res => {
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.countdowm();
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      } else {
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 点击注册
    handleSubmit() {
      const { phone, code, password } = this.form;
      if(!phone) {
        this.$message({
          message: '请输入手机号！',
          type: 'warning',
        });
        return
      }
      if(!code) {
        this.$message({
          message: '请输入验证码！',
          type: 'warning',
        });
        return
      }
      if(!password) {
        this.$message({
          message: '请输入密码！',
          type: 'warning',
        });
        return
      }
      let data = {
        IsQuickRregister: true,
        Mobile: phone,
        Code: code,
        Token: this.guid,
        LoginType: 1,
        AuthMode: 1,
        Pwd: password,
      };
      quickRegister(data).then(res => {
        if (res.ok == 1) {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
          const { Account, Password } = res.data;
          const accountData = btoa(JSON.stringify({a: Account, b: Password}))
          this.$router.push({path: '/subject/registerSuccess', query: { _d: accountData}});
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.scratcher {
  background: #cb2211;
  max-width: 750px;
  margin: auto;
  min-height: 100vh;
  padding: 3.2rem 0 1rem;

  .hover {
    cursor: pointer;
  }
  .w-full {
    width: 100%;
  }
  &_header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 750px;
    background: url('/subject/scratcher/headerBg.png') no-repeat;
    background-size: 100% 100%;
    z-index: 101;
    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1.25rem;

      &-left {
        display: flex;
        align-items: center;
      }
      &-logo {
        background: #fff;
        border-radius: 0.5rem;
        margin-right:  0.8rem;
        width: 2.6rem;
        height: 2.6rem;
        text-align: center;
        img {
          margin-top: 0.25rem;
          width: 2.1rem;
          height: 2.1rem;
        }
      }
      &-edition {
        h4 {
          color: #865430;
          margin: 0;
          font-size: 1.2rem;
          font-weight: 900;
        }
        p {
          font-size: 0.7rem;
          color: #fe9b46;
        }
      }
      &-btn {
        width: 6.5rem;
        line-height: 2.5rem;
        background: #ff5d15;
        color: #fff;
        text-align: center;
        border-radius: 0.5rem;
        font-size: 0.95rem;
      }
    }
  }
  &_introduce {
    background: url('/subject/scratcher/introduceBorder.png') no-repeat;
    background-size: 100% 100%;
    padding: 2.41rem 1.85rem 0.6rem;
    font-size: 0.75rem;
    color: #ffd1a5;
  }
  &_step-one {
    background: url('/subject/scratcher/bodyBg.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 0.5rem 0;
    &-title {
      width: 85%;
    }
    &-prize {
      width: 80%;
      margin: 0.5rem 0 0.2rem;
    }
    &-rules {
      width: 100%;
      // margin: 0.5rem 0 1rem;
    }
  }
  &_step-two {
    position: relative;
    &-leftBtn {
      position: absolute;
      width: 16%;
      height: 15%;
      top: 20%;
      left: 33%;
      cursor: pointer;
    }
    &-rightBtn {
      position: absolute;
      width: 16%;
      height: 15%;
      top: 20%;
      right: 6%;
      cursor: pointer;
    }
  }
  &_tips {
    text-align: center;
    color: #ff8d34;
    font-size: 0.9rem;
    margin: 1rem 0 2rem;
  }
  .scratcher-btns {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0.3rem auto 1rem;
    img {
      width: 48%;
    }
  }
}

.register-dialog {
  /deep/ .el-dialog {
    background: url('/subject/scratcher/registerBg.png') no-repeat;
    background-size: 100% 100%;
    max-width: 600px;
  }
  /deep/ .el-dialog__body {
    padding: 0 0 3rem;
    width: 80%;
    margin: auto;
  }
  .register-close {
    position: absolute;
    right: 11%;
    top: 9%;
    width: 25px;
  }
  .register-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    color: #000;
    margin: 1.5rem 0 0;
    span {
      color: #f12e1a;
    }
  }
  .register-form {
    margin: 7% 7% 18%;

    &-code {
      position: absolute;
      right: 0;
      background: #f12e1a;
      color: #fff;
      padding: 0 0.5rem;
      height: 8vw;
      border-radius: 6px;
      max-height: 40px;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      top: 17%;
      right: 0.5rem;
    }

    &-code-unfocus {
      background: #999999;
    }

    /deep/ .el-form-item {
      margin-bottom: 7.5%;
    }

    /deep/ .el-input__inner {
      border: none;
      background: #f5f6f7;
      height: 12vw;
      line-height: 12vw;
      border-radius: 6px;
      max-height: 70px;
      font-size: 0.8rem;
    }
    /deep/ .pwd-input .el-input__inner::placeholder {
      font-size: 0.68rem;
    }
  }
  .register-tips {
    font-size: 0.75rem;
    text-align: center;
    color: #fff;
    margin-bottom: 0.8rem;
    opacity: 0;
    overflow: hidden;
    height: 0;
    span {
      color: #fac483;
    }

  }
  
}
.codeDialog {
  width: 90%;
  height: 180px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fee6e4;
  border-radius: 15px;
  z-index: 99999;
  border: 1px solid #ffdbdb;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 550px;

  img {
    width: 120px;
    margin-bottom: 0;
  }

  p {
    span {
      color: #5f0e15;
      font-size: 15px;
      font-weight: 600;
    }

    input {
      width: 150px;
      height: 30px;
      background-color: #ffffff;
      border: none;
      margin-left: 10px;
      text-indent: 10px;
      color: #5f0e15;
      font-size: 14px;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 90px;
      height: 30px;
      border: 1px solid #f64b44;
      background-color: #ffffff;
      color: #f64b44;
      font-size: 15px;
      border-radius: 8px;
      margin: 0 10px;
    }

    button:nth-child(2) {
      background-color: #f64b44;
      color: #ffffff;
    }
  }
}

</style>
